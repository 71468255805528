import Breadcrumbs from '~/components/elements/Breadcrumbs'
import type { Merk, Page_Flexcontent_Flex_Posts } from '~/graphql/types'
import Brand from './post-types/Brand'
import clsx from 'clsx'

export default function BrandsOverview({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const background = fields.background === '#E9EAEA' ? 'light' : fields.background === '#56585E' ? 'grey' : 'dark'
  const theme = !fields?.backgroundTegels
    ? 'grey'
    : fields.backgroundTegels === '#E9EAEA'
    ? 'light'
    : fields.backgroundTegels === '#56585E'
    ? 'grey'
    : 'dark'

  return (
    <section data-component="BrandsOverview">
      <div className="bg-site-alt">
        <div className="container">
          <div className="flex flex-col gap-7 lg:gap-11 pb-10 sm:pb-14 lg:pb-24 pt-6 sm:pt-11">
            {fields?.showBreadcrumbs && <Breadcrumbs />}
            <h1 className="text-4xl sm:text-5xl xl:text-6xl pl-6 lg:pl-16 text-white font-semibold">{fields.title}</h1>
          </div>
        </div>
      </div>
      <div
        className={clsx(
          'section section--with-bg',
          background === 'light' && 'bg-site-grey',
          background === 'grey' && 'bg-site-alt',
          background === 'dark' && 'bg-site-base'
        )}
      >
        <div className="container">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 xm:gap-4 md:gap-6">
            {fields?.posts?.edges
              ?.filter((brand) => brand?.node?.parentDatabaseId === null)
              .map(({ node }) => {
                const brand = node as Merk

                return <Brand darktext={fields.textcolor === 'black'} type={theme} key={brand.databaseId} data={brand} />
              })}
          </div>
        </div>
      </div>
    </section>
  )
}
