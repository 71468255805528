import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useState } from 'react'
import { Arrow } from '~/components/elements/Arrow'
import Content from '~/components/elements/Content'
import { Subtitle } from '~/components/elements/Subtitle'
import { Title } from '~/components/elements/Title'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentCustom({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const [index, setIndex] = useState(0)
  const theme = !fields?.background
    ? 'light'
    : fields.background === '#E9EAEA'
    ? 'light'
    : fields?.background === '#FFFFFF'
    ? 'light'
    : 'dark'
  const color = !fields?.background
    ? 'base'
    : fields.background === '#FFFFFF'
    ? 'base'
    : fields?.background === '#E9EAEA'
    ? 'base'
    : 'white'

  const items = fields?.itemsCustom as any[]

  return (
    <section
      data-component="ContenCustom"
      className={clsx(
        !fields?.background && 'section section--with-bg bg-site-grey',
        fields.background === '#E9EAEA' && 'section section--with-bg bg-site-grey',
        fields.background === '#393C42' && 'section section--with-bg bg-site-base',
        fields.background === '#FFFFFF' && 'section section--with-bg bg-white'
      )}
    >
      <div className="container flex flex-col gap-4 items-start md:items-center">
        <Subtitle type={theme}>{fields?.subtitle}</Subtitle>
        <Title type={theme} size="small">
          {fields?.title}
        </Title>

        <div className="flex flex-row mt-8 md:mt-16 gap-1 md:gap-8 items-center">
          <div
            className="h-full cursor-pointer hidden lg:block"
            onClick={() => setIndex((prevIndex) => (prevIndex === 0 ? items.length - 1 : prevIndex - 1))}
          >
            <Arrow orientation="left" color={color} hover="accent" />
          </div>
          <div
            key={index}
            className="max-lg:flex max-lg:flex-col lg:grid lg:min-h-[490px] xl:min-h-[530px] lg:grid-cols-2 gap-10 xl:gap-16"
          >
            <div className={clsx(items[index].imagePosition === 'right' && 'lg:order-2', 'flex flex-row gap-4 md:gap-8 items-center')}>
              <div
                className="h-full flex items-center cursor-pointer lg:hidden"
                onClick={() => setIndex((prevIndex) => (prevIndex === 0 ? items.length - 1 : prevIndex - 1))}
              >
                <Arrow orientation="left" color={color} hover="accent" />
              </div>
              <div className="h-full w-full left-0 top-0 overflow-hidden rounded-[7px]">
                <LossePlaatjie
                  loading="eager"
                  className="w-full h-full object-cover rounded-[7px] animate-fade-left animate-ease-in-out"
                  src={items[index].image}
                  maxwidth={530}
                />
              </div>
              <div
                className="h-full flex items-center cursor-pointer lg:hidden"
                onClick={() => setIndex((prevIndex) => (prevIndex === items.length - 1 ? 0 : prevIndex + 1))}
              >
                <Arrow color={color} hover="accent" />
              </div>
            </div>
            <div
              className={clsx(
                theme === 'light' && 'children:text-site-base',
                theme === 'dark' && 'children:text-white',
                'flex flex-col animate-fade-left animate-ease-in-out animate-delay-75 lg:justify-center'
              )}
            >
              <div className="font-semibold text-3xl">{items[index].title}</div>
              {items[index]?.description && (
                <Content
                  className={clsx(
                    'mt-6 pb-8',
                    theme === 'light' && 'children-p:text-site-base',
                    theme === 'dark' && 'children-p:text-white'
                  )}
                >
                  {items[index]?.description}
                </Content>
              )}
            </div>
          </div>

          <div
            className="h-full cursor-pointer hidden lg:block"
            onClick={() => setIndex((prevIndex) => (prevIndex === items.length - 1 ? 0 : prevIndex + 1))}
          >
            <Arrow color={color} hover="accent" />
          </div>
        </div>
      </div>
    </section>
  )
}
